








import { Vue, Component } from 'vue-property-decorator';
import Header from './layout/header.vue';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';
const System = namespace('System');
@Component({
  components: { Header }
})
export default class Main extends Vue {
  @System.Mutation setActiveTabs: any;

  beforeRouteEnter(to: Route, from: Route, next: (vm: any) => void) {
    next((vm: any) => {
      vm.setActiveTabs({ title: to.meta?.title, bread: to.meta?.bread, fullPath: to.fullPath, path: to.path, fromPath: from.path });
      vm.activeKey = to.fullPath;
    });
  }

  beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    this.setActiveTabs({ title: to.meta?.title, bread: to.meta?.bread, fullPath: to.fullPath, path: to.path, fromPath: from.path });
    next();
  }
}
