













import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const System = namespace('System');

@Component
export default class Header extends mixins(MenuMixin) {
  current: string[] = ['task'];

  @System.State activeTabs: any;
  @System.Mutation setActiveTabs: any;

  created() {
    this.setCurrent();
  }

  /**
   * 设定初始高亮菜单
   */
  setCurrent() {
    let path = this.$route.path;
    let cur = path.split('/');
    this.current = [cur[1]];
  }

  routeTo(e: { key: string }) {
    let index = this.activeTabs.findIndex((v: any) => v.path.startsWith(`/${e.key}`));
    if (index > -1) {
      this.setActiveTabs(this.activeTabs[index]);
      this.$router.push(this.activeTabs[index].fullPath);
    } else {
      this.$router.push({
        path: `/${e.key}`
      });
    }
  }
}
